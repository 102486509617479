const FACTURE_CLIENT_INFO = "bss_new_facture_user_client_info";
const FACTURE_OPERATORS = "bss_new_facture_user_operators";

const ESIR_CLIENT_INFO = "bss_new_esir_user_client_info";
const ESIR_OPERATORS = "bss_new_esir_user_operators";
const ESIR_LOCATIONS = "bss_new_esir_user_locations";
const ADDRESS_PATH = "bss_user_delivery_address";
const LOGGED_OPERATOR_PATH = "bss_new_esir_operator";

const THEME_PATH = "bss_new_theme";

const eFactureClientInit = {
    uid: "",
    pib: "",
    firmName: "",
    mb: "",
    address: "",
    city: "",
    telephone: "",
    email: "",
    contactPerson: "",
    contactEmail: "",
    password: "",
    product: "0",
    template: "default",
    contractTypeID: "8",
    contractTypeIDeFakture: "2",
    contractStartDate: null,
    apiKey: "",
    isProduction: true,
    note: ""
};

const esirClientInit = {
    uid: "",
    pib: "",
    firmName: "",
    mb: "",
    address: "",
    city: "",
    telephone: "",
    email: "",
    contactPerson: "",
    contactEmail: "",
    password: "",
    product: "0",
    template: "default",
    contractTypeID: "0",
    contractTypeIDeFakture: "0",
    contractStartDate: null,
    apiKey: "",
    isProduction: true,
    note: ""
};

// New eFacture local storage
export function getClientInfoFromLocalStorage(isFacture) {
    let storage;
    if (isFacture) {
        storage = localStorage.getItem(FACTURE_CLIENT_INFO);
        if (storage) {
            return JSON.parse(storage);
        }
        return eFactureClientInit;
    } else {
        storage = localStorage.getItem(ESIR_CLIENT_INFO);
        if (storage) {
            return JSON.parse(storage);
        }
        return esirClientInit;
    }
}

export function saveToLocalStorageClientInfo(clientInfo, isFacture) {
    localStorage.setItem(isFacture ? FACTURE_CLIENT_INFO : ESIR_CLIENT_INFO, JSON.stringify(clientInfo));
}

export function addResponseToLocalStorageClientInfo(response, isFacture){
    const clientInfo = getClientInfoFromLocalStorage(isFacture);
    localStorage.setItem(isFacture ? FACTURE_CLIENT_INFO : ESIR_CLIENT_INFO, JSON.stringify({
        ...clientInfo,
        response
    }));
}

export function getOperatorsFromLocalStorage(isFacture) {
    let storage;
    if (isFacture) {
        storage = localStorage.getItem(FACTURE_OPERATORS);
        return storage ? JSON.parse(storage) : [{
            username: "",
            secret: "",
            group: "31"
        }]
    } else {
        storage = localStorage.getItem(ESIR_OPERATORS);
        return storage ? JSON.parse(storage) : [{
            username: "",
            secret: "",
            group: "0"
        }]
    }
}

export function saveToLocalStorageOperators(operators, isFacture) {
    if (isFacture) {
        localStorage.setItem(FACTURE_OPERATORS, JSON.stringify(operators));
    } else {
        localStorage.setItem(ESIR_OPERATORS, JSON.stringify(operators));
    }
}

// ----------- LOKACIJE ---------------
export function getLocationsFromLocalStorage() {
    let storage = localStorage.getItem(ESIR_LOCATIONS);
    return storage ? JSON.parse(storage) : [{
        name: "",
        cert: "",
        key: "",
        JID: "",
        PAC: ""
    }];
}

export function saveToLocalStorageLocations(locations) {
    localStorage.setItem(ESIR_LOCATIONS, JSON.stringify(locations));
}

export function clearSavedInsert(isFacture) {
    if (isFacture) {
        localStorage.removeItem(FACTURE_CLIENT_INFO)
        localStorage.removeItem(FACTURE_OPERATORS)
    } else {
        localStorage.removeItem(ESIR_CLIENT_INFO)
        localStorage.removeItem(ESIR_OPERATORS)
        localStorage.removeItem(ESIR_LOCATIONS)
    }
}

// ----------- TEMA -------------------
export function setSessionTheme(theme) {
    localStorage.setItem(THEME_PATH, theme);
}

export function getSessionTheme() {
    let theme = localStorage.getItem(THEME_PATH);
    return theme ? theme : "light";
}

// ---------- ADRESE -------------------
export function getSavedAddress() {
    return localStorage.getItem(ADDRESS_PATH) ? JSON.parse(localStorage.getItem(ADDRESS_PATH)) : []
}

export function saveAddress(address) {
    let arr = [...getSavedAddress()]
    if(address.isDefault){
        arr = arr.map(address=> ({
            ...address,
            isDefault: false
        }))
    }
    arr.push(address);
    localStorage.setItem(ADDRESS_PATH, JSON.stringify(arr))
}

export function deleteAddress(index) {
    const arr = [...getSavedAddress()]
    arr.splice(index, 1)
    localStorage.setItem(ADDRESS_PATH, JSON.stringify(arr))
}

export function editAddress(address, index) {
    const arr = [...getSavedAddress()]
    arr[index] = address
    localStorage.setItem(ADDRESS_PATH, JSON.stringify(arr))
}

const SELECTED_LOCATION_DENSE = "efakture-web-selected-dense";
const SELECTED_LOCATION_TABLE_ROWS_PER_PAGE = "efakture-web-selected-rows-per-page";

// ---------------- TABELA ----------------
export function setSessionTableRowsPerPage(rowsPerPage) {
    localStorage.setItem(SELECTED_LOCATION_TABLE_ROWS_PER_PAGE, JSON.stringify(rowsPerPage));
}

export function getSessionTableRowsPerPage(){
    return localStorage.getItem(SELECTED_LOCATION_TABLE_ROWS_PER_PAGE)
        ? JSON.parse(localStorage.getItem(SELECTED_LOCATION_TABLE_ROWS_PER_PAGE))
        : 25;
}

export function setSessionDense(dense) {
    localStorage.setItem(SELECTED_LOCATION_DENSE, JSON.stringify(dense));
}

export function getSessionDense(){
    return localStorage.getItem(SELECTED_LOCATION_DENSE)
        ? JSON.parse(localStorage.getItem(SELECTED_LOCATION_DENSE))
        : true;
}
// ---------------- OPERATORS ----------------
export function setSessionOperator(operator) {
    localStorage.setItem(LOGGED_OPERATOR_PATH, JSON.stringify(operator));
}
export function getSessionOperator(){
    return localStorage.getItem(LOGGED_OPERATOR_PATH)
        ? JSON.parse(localStorage.getItem(LOGGED_OPERATOR_PATH))
        : null;
}
export function deleteSessionOperator() {
    localStorage.removeItem(LOGGED_OPERATOR_PATH);
}
