import PropTypes from "prop-types";
// form
import { Controller, useFormContext } from "react-hook-form";
// @mui
import { TextField } from "@mui/material";

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string
};

export default function RHFSelect({ name, multiple = false, children, defaultValue = "", ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          size="small"
          {...field}
          select
          fullWidth
          SelectProps={{ native: true, multiple: multiple }}
          error={!!error}
          helperText={error?.message}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
