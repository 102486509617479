import { getAllClients } from "../../api";
import { deleteAllClients, insertClientOffline, insertSyncData } from "../../store/offlineDb";

export async function SyncClients() {
  await deleteAllClients();
  await insertSyncData({ date: Date.now() });
  return new Promise(async (resolve, reject) => {
    await getClientFrom(null, [], resolve, reject);
  });
}

export async function getClientFrom(lastData, arr, resolve, reject) {
  try {
    const response = (await getAllClients(lastData, 50)).data;
    for (const client of response) {
      await insertClientOffline(client);
      arr.push(client);
    }
    if (response.length < 50) {
      return resolve(arr);
    } else {
      await getClientFrom(response[response.length - 1].contractID, arr, resolve, reject);
    }
  } catch (e) {
    console.error("getInvoicesByUser", e);
    reject(e);
  }
}
