import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {child, get, push, remove, update} from "firebase/database";
import {AUTH, dbRef} from "../../../auth/FirebaseContext";
import {getSessionOperator, setSessionOperator} from "../../../helper/session";

export const fetchAllBssOperators = createAsyncThunk("fetchAllBssOperators", async () => {
    const operators = (await get(child(dbRef, `bss/tenants/${AUTH.currentUser.uid}/operators`))).val();
    return operators ? Object.keys(operators).map(key => ({
        ...operators[key],
        uid: key
    })) : [];
});

export const fetchAllBssOperatorsGroups = createAsyncThunk("fetchAllBssOperatorsGroups", async () => {
    return (await get(child(dbRef, `/bss/public/operatorGroups`))).val();
});
export const deleteBssOperator = createAsyncThunk("deleteBssOperator", async (data) => {
    await remove(child(dbRef, `bss/tenants/${AUTH.currentUser.uid}/operators/${data}`))
    const sessionOperator = getSessionOperator();
    if(sessionOperator?.uid === data){
        setSessionOperator(null);
        window.location.reload();
    }
});
export const createUpdateBssOperator = createAsyncThunk("createUpdateBssOperator", async (data) => {
    if (data.uid) {
        (await update(child(dbRef, `bss/tenants/${AUTH.currentUser.uid}/operators/${data.uid}`), {
            username: data.username,
            secret: data.secret,
            group: Number(data.group),
        }));
        const sessionOperator = getSessionOperator();
        if(sessionOperator?.uid === data.uid){
            setSessionOperator(data);
            window.location.reload();
        }
        return {operator: data, isNew: false};
    } else {
        const uid = (await push(child(dbRef, `bss/tenants/${AUTH.currentUser.uid}/operators`), {
            username: data.username,
            secret: data.secret,
            group: Number(data.group),
        }))
        return {
            operator: {
                ...data,
                uid: uid,
            }, isNew: true
        };
    }
});

const initialState = {
    operators: [],
    groups: [],
    loading: false,
    loadingGroups: false,
};

export const slice = createSlice({
    name: "adminOperators",
    initialState,
    reducers: {},
    extraReducers: {
        //fetchAdvancedSearchUsers
        [fetchAllBssOperators.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllBssOperators.fulfilled]: (state, {payload}) => {
            state.operators = payload
            state.loading = false;
        },
        [fetchAllBssOperators.rejected]: (state) => {
            state.loading = false;
        },
        //fetchAllBssOperatorsGroups
        [fetchAllBssOperatorsGroups.pending]: (state) => {
            state.loadingGroups = true;
        },
        [fetchAllBssOperatorsGroups.fulfilled]: (state, {payload}) => {
            state.groups = payload;
            state.loadingGroups = false;
        },
        [fetchAllBssOperatorsGroups.rejected]: (state) => {
            state.loadingGroups = false;
        },
        //deleteBssOperator
        [deleteBssOperator.fulfilled]: (state, {meta}) => {
            state.operators = state.operators.filter(op => op.uid !== meta.arg)
        },
        //createUpdateBssOperator
        [createUpdateBssOperator.fulfilled]: (state, {payload}) => {
            const {operator, isNew} = payload;
            const arr = [...current(state.operators)];
            if (isNew) {
                arr.push(operator);
            } else {
                const index = arr.findIndex(op => op.uid === operator.uid);
                if (index > -1) {
                    arr[index] = operator
                }
            }
            state.operators = arr;
        },
    }
});
export const {resetFoundedResult} = slice.actions
export default slice.reducer;


