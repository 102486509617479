import PropTypes from 'prop-types';
import {m} from 'framer-motion';
// @mui
import {Container, Typography} from '@mui/material';
import MotionContainer from "../components/animate/MotionContainer";
import {varBounce} from "../components/animate";
import ForbiddenIllustration from "../assets/illustratuions/ForbiddenIllustration";
import {useAuthContext} from "./useAuthContext";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {dispatch} from "../redux/store";
import {fetchAllBssOperatorsGroups} from "../redux/slices/admin/operators";
import {useLocation} from "react-router-dom";
import {includePath} from "../helper";
// components


// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
    children: PropTypes.node,
    hasContent: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.number),
};

const NoPermissions = () => <Container component={MotionContainer} sx={{textAlign: 'center'}}>
    <m.div variants={varBounce().in}>
        <Typography variant="h3" paragraph>
            Permission Denied
        </Typography>
    </m.div>

    <m.div variants={varBounce().in}>
        <Typography sx={{color: 'text.secondary'}}>You do not have permission to access this
            page</Typography>
    </m.div>

    <m.div variants={varBounce().in}>
        <ForbiddenIllustration sx={{height: 260, my: {xs: 5, sm: 10}}}/>
    </m.div>
</Container>

export default function RoleBasedGuard({hasContent, roles, children}) {
    // Logic here to get current user role
    const {role, operator} = useAuthContext();
    const {pathname} = useLocation();
    const {groups, loadingGroups} = useSelector(state => state.adminOperators);
    const groupPaths = operator ? groups[Number(operator.group)]?.paths : undefined;

    useEffect(() => {
        if (groups.length === 0 && !loadingGroups) {
            dispatch(fetchAllBssOperatorsGroups());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups.length, loadingGroups]);

    if (!!groupPaths && !includePath(groupPaths, pathname)) {
        return <NoPermissions/>;
    }

    if (typeof roles !== 'undefined' && !roles.includes(role)) {
        return hasContent ? (
            <NoPermissions/>
        ) : null;
    }

    return <>{children}</>;
}
