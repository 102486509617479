import { insertLocation, insertOperator, insertSeller } from "../api";
import { insertClientOffline } from "../store/offlineDb";
import {
  addResponseToLocalStorageClientInfo,
  saveToLocalStorageLocations,
  saveToLocalStorageOperators
} from "./session";
import { X509 } from "jsrsasign";

export async function checkAndInsertNewClient(client, operators, locations, isFacture) {
  let response = {
    uid: undefined,
    errorClient: undefined,
    errorOperators: undefined,
    errorLocations: undefined
  };
  try {
    let clientResponse = client.response;
    if (clientResponse === undefined) {
      clientResponse = (await insertSeller(client)).data;
      await insertClientOffline({
        ...clientResponse,
        license: []
      });
      addResponseToLocalStorageClientInfo(clientResponse, isFacture);
    }
    response = {
      ...response,
      uid: clientResponse.uid
    }
    let newArr = [];
    let errors = [];
    let i  = 1;
    for (const operator of operators) {
      try {
        if (operator.response === undefined) {
          await insertOperator({
            uid: clientResponse.uid,
            ...operator
          });
        }
        newArr.push({
          ...operator,
          response: "success"
        });
      } catch (e) {
        errors.push(`Greška prilikom dodavanja operatera pod rednim brojem: ${i}. Pokušajte ponovo!`);
      }
      i = i + 1;
    }
    if (errors.length > 0) {
      response = {
        ...response,
        errorOperators: errors
      };
    }
    saveToLocalStorageOperators(newArr, isFacture);
    errors = [];
    newArr = [];
    i = 1;
    if (locations) {
      for (const location of locations) {
        try {
          if (location.response === undefined) {
            await insertLocation({
              ...location,
              uid: clientResponse.uid
            });
          }
          newArr.push({
            ...location,
            response: "success"
          });
        } catch (e) {
          errors.push(`Greška prilikom dodavanja lokacije pod rednim brojem: ${i}. Pokušajte ponovo!`);
        }
        i = i + 1;
      }
    }
    if (errors.length > 0) {
      response = {
        ...response,
        errorLocations: errors
      };
    }
    saveToLocalStorageLocations(newArr);
  } catch (reason) {
    console.error("insertSeller reason", reason);
    let message = "Greška prilikom dodavanja klijenta";
    let error = reason;
    if (reason.response.data)
      error = reason.response.data;
    else if (reason.error.message)
      error = reason.error.message;
    if (error.code) {
      // eslint-disable-next-line default-case
      switch (error.code) {
        case "auth/email-already-exists": {
          message = "Već postoji korisnik sa ovim email-om!";
          break;
        }
        case "auth/invalid-phone-number": {
          message = "Telefon nije validan!";
          break;
        }
        case "auth/phone-number-already-exists": {
          message = "Već postoji korisnik sa ovim telefonom!";
          break;
        }
      }
    }
    response = {
      ...response,
      errorClient: message
    };
  }
  return response;
}

export const filterPathsByOperator = (operator, groups, paths) => {
  // Ako nema operatora ili grupa, vraćamo originalni niz
  if (!operator || !groups) return paths;

  const group = groups[Number(operator.group)];
  if (!group) return paths;

  // Filtriramo "paths" i njihove "items" i "children"
  return paths
      .map((path) => {
        const filteredItems = path.items
            .map((item) => {
              if (item.children) {
                const filteredChildren = item.children.filter((child) =>
                    includePath(group.paths, child.path)
                );
                return filteredChildren.length > 0
                    ? { ...item, children: filteredChildren }
                    : null;
              }
              return includePath(group.paths, item.path) ? item : null;
            })
            .filter(Boolean); // Uklanja `null` vrednosti

        return filteredItems.length > 0
            ? { ...path, items: filteredItems }
            : null;
      })
      .filter(Boolean); // Uklanja `null` vrednosti

  // Vraćamo filtrirane putanje
};

// Funkcija za proveru da li je putanja uključena u grupu
export const includePath = (groupPaths, path) =>{
  if(!groupPaths){
    return true;
  }
  return groupPaths.some((groupPath) =>
      groupPath.endsWith("/**")
          ? path.startsWith(groupPath.slice(0, -3)) // Uklanja "/**" i proverava početak
          : path === groupPath // Tačno poklapanje
  );
}

export const extractCertificateExpiry = (pemCertificate) => {
  try {
    const x509 = new X509();
    x509.readCertPEM(pemCertificate);
    const notAfter = x509.getNotAfter();
    return new Date(
        Date.UTC(
            parseInt(notAfter.slice(0, 2)) + 2000,
            parseInt(notAfter.slice(2, 4)) - 1,
            parseInt(notAfter.slice(4, 6)),
            parseInt(notAfter.slice(6, 8)),
            parseInt(notAfter.slice(8, 10)),
            parseInt(notAfter.slice(10, 12))
        )
    );
  } catch (error) {
    console.error("Failed to parse certificate:", error);
    return null;
  }
};