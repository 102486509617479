import PropTypes from 'prop-types';
import {Navigate, useLocation} from 'react-router-dom';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../routes/path';
// components
import LoadingScreen from '../components/loading-screen';

//
import {useAuthContext} from "../auth/useAuthContext";
// ----------------------------------------------------------------------

GuestGuard.propTypes = {
    children: PropTypes.node,
};

export default function GuestGuard({children}) {
    const {isAuthenticated, isInitialized, operators, operator} = useAuthContext();
    const {pathname} = useLocation();

    if (!isAuthenticated && pathname !== PATH_AUTH.login) {
        return <Navigate to={PATH_AUTH.login}/>;
    }

    if (isAuthenticated) {
        if (operators.length === 0) {
            return <Navigate to={PATH_DASHBOARD.general.app}/>;
        }
        if (operator) {
            return <Navigate to={PATH_DASHBOARD.general.app}/>;
        }
        if (pathname !== PATH_AUTH.operatorLogin && !operator) {
            return <Navigate to={PATH_AUTH.operatorLogin}/>;
        }
    }

    if (!isInitialized) {
        return <LoadingScreen/>;
    }

    return <>{children}</>;
}
