import {useEffect, useLayoutEffect, useState} from "react";
import {Outlet} from "react-router-dom";
// @mui
import {Box} from "@mui/material";
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import {useSettingsContext} from "../../components/settings";
//
import Main from "./Main";
import Header from "./header";
import NavMini from "./nav/NavMini";
import NavVertical from "./nav/NavVertical";
import {dispatch} from "../../redux/store";
import {clientsSync} from "../../redux/slices/clients";
import {AUTH} from "../../auth/FirebaseContext";
import {notificationsListener} from "../../helper/firestore/listeners";
import {fetchAllBssOperatorsGroups} from "../../redux/slices/admin/operators";
import {useSelector} from "react-redux";
import RoleBasedGuard from "../../auth/RoleBasedGuard";
// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const {themeLayout} = useSettingsContext();
    const {groups, loadingGroups} = useSelector(state => state.adminOperators);

    const isDesktop = useResponsive("up", "lg");

    const [open, setOpen] = useState(false);

    const isNavMini = themeLayout === "mini";

    useEffect(() => {
        if (AUTH.currentUser?.uid) {
            notificationsListener()
        }
    }, [])

    useLayoutEffect(() => {
        if (AUTH.currentUser?.uid) {
            dispatch(clientsSync()).unwrap().catch(reason => {
                console.error("clientsSync", reason);
            });
        }
    }, []);

    useEffect(() => {
        if (groups.length === 0 && !loadingGroups) {
            dispatch(fetchAllBssOperatorsGroups());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups.length, loadingGroups]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose}/>;

    if (isNavMini) {
        return (
            <>
                <Header onOpenNav={handleOpen}/>

                <Box
                    sx={{
                        display: {lg: "flex"},
                        minHeight: {lg: 1}
                    }}>
                    {isDesktop ? <NavMini/> : renderNavVertical}

                    <Main>
                        <Outlet/>
                    </Main>
                </Box>
            </>
        );
    }

    return (
        <>
            <Header onOpenNav={handleOpen}/>

            <Box
                sx={{
                    display: {lg: "flex"},
                    minHeight: {lg: 1}
                }}>
                {renderNavVertical}

                <Main>
                    <RoleBasedGuard>
                        <Outlet/>
                    </RoleBasedGuard>
                </Main>
            </Box>
        </>
    );
}
