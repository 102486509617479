import PropTypes from "prop-types";
import {createContext, useCallback, useEffect, useReducer} from "react";
import {initializeApp} from "firebase/app";
import {getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut} from "firebase/auth";
import {child, get, getDatabase, ref} from "firebase/database";

import {doc, getDoc, getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

// config
import {FIREBASE_API} from "../config";
import {deleteSessionOperator, getSessionOperator, setSessionOperator} from "../helper/session";

// ----------------------------------------------------------------------

const initialState = {
    isInitialized: false,
    isAuthenticated: false,
    user: null,
    operators: [],
    operator: getSessionOperator()
};

const reducer = (state, action) => {
    if (action.type === "INITIAL") {
        return {
            isInitialized: true,
            isAuthenticated: action.payload.isAuthenticated,
            user: action.payload.user,
            role: action.payload.role,
            cPrefix: action.payload.cPrefix,
            operators: action.payload.operators,
            operator: getSessionOperator(),
        };
    }
    else if(action.type === "OPERATOR_LOGIN") {
        return {
            ...state,
            operator: action.payload.operator,
        }
    }

    return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);

export const DB = getFirestore(firebaseApp);

export const RDB = getDatabase(firebaseApp);

export const dbRef = ref(RDB);

export const storageRef = getStorage(firebaseApp);

AuthProvider.propTypes = {
    children: PropTypes.node
};

export function AuthProvider({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const initialize = useCallback(() => {
        try {
            onAuthStateChanged(AUTH, async (user) => {
                if (user) {
                    const userRef = doc(DB, "users", user.uid);
                    const tenantData = (await get(child(dbRef, `bss/tenants/${user.uid}/protected`))).val();
                    const operators = (await get(child(dbRef, `bss/tenants/${user.uid}/operators`))).val();
                    const docSnap = await getDoc(userRef);
                    const profile = docSnap.data();
                    const details = await getUserInfo(user);
                    dispatch({
                        type: "INITIAL",
                        payload: {
                            isAuthenticated: true,
                            user: {
                                ...user,
                                ...profile,
                                ...details
                            },
                            role: tenantData?.groupID,
                            cPrefix: tenantData?.contractPrefix,
                            operators: operators ? Object.keys(operators).map(key => ({
                                uid: key,
                                ...operators[key]
                            })) : []
                        }
                    });
                } else {
                    dispatch({
                        type: "INITIAL",
                        payload: {
                            isAuthenticated: false,
                            user: null,
                            role: null,
                            cPrefix: null,
                            operators: []
                        }
                    });
                }
            });
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    // LOGIN
    const login = async (email, password) => {
        const user = (await signInWithEmailAndPassword(AUTH, email, password)).user;
        let role = (await get(child(dbRef, `bss/tenants/${user.uid}/protected/groupID`))).val();
        if (role === null) {
            await logout();
            throw new Error("NO ROLE");
        }
        return {
            ...user,
            role
        };
    };

    const getUserInfo = async (user) => {
        try {
            const tin = (await get(child(dbRef, `users/${user.uid}/profile/pub`))).val();
            return (await get(child(dbRef, `public/businesses/${tin?.TIN}`))).val();
        } catch (e) {
            console.error("e", e);
            return undefined;
        }
    };

    // LOGOUT
    const logout = async () => {
        await signOut(AUTH);
        deleteSessionOperator();
        dispatch({
            type: "OPERATOR_LOGIN",
            payload: {
                operator: null
            }
        })
    };

    const operatorLogin = (operator) => {
      setSessionOperator(operator);
      dispatch({
          type: "OPERATOR_LOGIN",
          payload: {
              operator
          }
      })
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "firebase",
                login,
                logout,
                operatorLogin
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
