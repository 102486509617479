import PropTypes from "prop-types";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
// @mui
import {Box, Drawer, Stack, Typography} from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// config
import {NAV} from "../../../config";
// components
import Scrollbar from "../../../components/Scrollbar";
import {NavSectionVertical} from "../../../components/nav-section";
//
import navConfig from "./config";

import NavAccount from "./NavAccount";
import {useAuthContext} from "../../../auth/useAuthContext";
import NavToggleButton from "../../../components/NavToggleButton";
import {useSelector} from "react-redux";
import {filterPathsByOperator} from "../../../helper";

// ----------------------------------------------------------------------

NavVertical.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func
};

export default function NavVertical({openNav, onCloseNav}) {
    const {pathname} = useLocation();
    let isDesktop = useResponsive("up", "lg");
    const {user, operator} = useAuthContext();
    const {groups} = useSelector(state => state.adminOperators);

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                "& .simplebar-content": {
                    height: 1,
                    display: "flex",
                    flexDirection: "column"
                }
            }}>
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0
                }}>
                {/*<Logo />*/}
                <Typography sx={{ml: 1, fontWeight: 700}}>{user.displayName}</Typography>
                <NavAccount/>
            </Stack>

            <NavSectionVertical data={filterPathsByOperator(operator, groups, navConfig)}/>

            <Box sx={{flexGrow: 1}}/>

        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            sx={{
                flexShrink: {lg: 0},
                width: {lg: NAV.W_DASHBOARD}
            }}>
            <NavToggleButton/>
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{
                        sx: {
                            zIndex: 0,
                            width: NAV.W_DASHBOARD,
                            bgcolor: "transparent",
                            borderRightStyle: "dashed"
                        }
                    }}>
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{
                        keepMounted: true
                    }}
                    PaperProps={{
                        sx: {
                            width: NAV.W_DASHBOARD
                        }
                    }}>
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
