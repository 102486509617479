// @mui
import {Box, Stack} from '@mui/material';
// config
import {NAV} from '../../../config';
// utils
import {hideScrollbarX} from '../../../utils/cssStyles';
// components
import Logo from '../../../components/logo';
import {NavSectionMini} from '../../../components/nav-section';
//
import navConfig from './config';
import NavToggleButton from "../../../components/NavToggleButton";
import {filterPathsByOperator} from "../../../helper";
import {useAuthContext} from "../../../auth/useAuthContext";
import {useSelector} from "react-redux";

// ----------------------------------------------------------------------

export default function NavMini() {
    const {operator} = useAuthContext();
    const {groups} = useSelector(state => state.adminOperators);
    return (
        <Box
            component="nav"
            sx={{
                flexShrink: {lg: 0},
                width: {lg: NAV.W_DASHBOARD_MINI},
            }}>
            <NavToggleButton
                sx={{
                    top: 22,
                    left: NAV.W_DASHBOARD_MINI - 12,
                }}
            />

            <Stack
                sx={{
                    pb: 2,
                    height: 1,
                    position: 'fixed',
                    width: NAV.W_DASHBOARD_MINI,
                    borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                    ...hideScrollbarX,
                }}>
                <Logo sx={{mx: 'auto', my: 2}}/>

                <NavSectionMini data={filterPathsByOperator(operator, groups, navConfig)}/>
            </Stack>
        </Box>
    );
}
