import {lazy, Suspense} from "react";
// components
import LoadingScreen from "../components/loading-screen";
// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTHENTICATION
export const Login = Loadable(lazy(() => import("../pages/auth/LoginPage")));
export const OperatorLogin = Loadable(lazy(() => import("../pages/auth/OperatorLogin")));

//PAGES
export const HomePage = Loadable(lazy(() => import("../pages/Home")));
export const SettingsPage = Loadable(lazy(() => import("../pages/Settings")));
export const UserOrdersPage = Loadable(lazy(() => import("../pages/UserOrders")));
export const ChatPage = Loadable(lazy(() => import("../pages/Chat")));
export const HelpPage = Loadable(lazy(() => import("../pages/Help")));
export const PaymentsPage = Loadable(lazy(() => import("../pages/admin/Payments")));
export const SpecificationPage = Loadable(lazy(() => import("../pages/Specification")));
export const SpecificationPreviewPage = Loadable(lazy(() => import("../pages/Specification/preview")));
export const AllUsersPage = Loadable(lazy(() => import("../pages/admin/Users")));
export const AdvancedSearchPage = Loadable(lazy(() => import("../pages/admin/AdvancedSearch")));
export const ClientsPage = Loadable(lazy(() => import("../pages/Clients")));
export const NewEsirClientPage = Loadable(lazy(() => import("../pages/NewClient/esir")));
export const NewEfactureClientPage = Loadable(lazy(() => import("../pages/NewClient/eFacture")));
export const ClientPreviewPage = Loadable(lazy(() => import("../pages/ClientPreview")));
export const NewOperatorPage = Loadable(lazy(() => import("../pages/ClientPreview/Operators/OperatorsNew")));
export const EditOperatorPage = Loadable(lazy(() => import("../pages/ClientPreview/Operators/OperatorsEditPage")));
export const NewLocationPage = Loadable(lazy(() => import("../pages/ClientPreview/Locations/LocationNew")));
export const EditLocationPage = Loadable(lazy(() => import("../pages/ClientPreview/Locations/LocationEditPage")));
export const NewLicensePage = Loadable(lazy(() => import("../pages/ClientPreview/License/LicenseNew")));
export const EditLicensePage = Loadable(lazy(() => import("../pages/ClientPreview/License/LicenseEditPage")));
export const DebtorsPage = Loadable(lazy(() => import("../pages/admin/Debtors")));
export const TenantsPage = Loadable(lazy(() => import("../pages/admin/Tenants")));
export const TenantsReportPage = Loadable(lazy(() => import("../pages/admin/Tenants/TenantsReport")));
export const ToolsPage = Loadable(lazy(() => import("../pages/admin/Tools")));
export const LogsPage = Loadable(lazy(() => import("../pages/admin/Logs")));
// OPERATERI
export const OperatorsPage = Loadable(lazy(() => import("../pages/admin/Operators")));
export const NewBssOperatorPage = Loadable(lazy(() => import("../pages/admin/Operators/OperatorsNew")));
export const EditBssOperatorPage = Loadable(lazy(() => import("../pages/admin/Operators/OperatorsEditPage")));
