import {child, endAt, get, limitToFirst, orderByChild, push, query, ref, startAt, update} from "firebase/database";
import {AUTH, dbRef, RDB} from "../../auth/FirebaseContext";
import moment from "moment";
import {getSessionOperator} from "../session";
import {LOG_ACTIONS} from "../../constants";

export async function isUserHaveEFacture(userUid) {
    let eFacture = await (await get(child(dbRef, `users/${userUid}/protected/efakture`))).val();
    if (!eFacture) {
        return false;
    }
    return eFacture.apiKey;
}

export async function acceptedTermsOfUse(userUid) {
    return await (await get(child(dbRef, `users/${userUid}/private/settings/acceptedLegal`))).val();
}

export async function resetUserTermsOfUse(userUid, index) {
    await update(child(dbRef, `users/${userUid}/private/settings/acceptedLegal/${index}`), {
        accepted: false
    });
}

export async function getLicenseHistory(userUid) {
    return await (await get(child(dbRef, `users/${userUid}/protected/licenseHistory`))).val();
}

export async function getUserActiveLicense(userUid) {
    return await (await get(child(dbRef, `users/${userUid}/protected/license`))).val();
}

export async function insertEFactureLicenseForUser(userUid, data) {
    await addUserLog(userUid, LOG_ACTIONS.eFactureLicense, data);
    await update(child(dbRef, `users/${userUid}/protected/efakture`), {
        apiKey: data.apiKey,
        isProduction: data.isProduction,
        contractTypeID: data.contractTypeID
    });

    await update(child(dbRef, `users/${userUid}/protected/license/2`), {
        count: 1,
        expiredDate: calculateExpiredDate(data.contractTypeID, data.contractStartDate),
        productID: 2
    });
}

const calculateExpiredDate = (contractType, contractStartDate) => {
    let now = contractStartDate ? moment(contractStartDate) : moment();
    switch (contractType) {
        case "2":
            // eslint-disable-next-line
            return now.add("year", 1).set("date", 1).set("hour", 0).set("minute", 0).set("second", 0)
                .format("yyyy-MM-DD HH:mm:ss")
        default:
            // eslint-disable-next-line
            return now.add("month", 2).set("date", 1).set("hour", 0).set("minute", 0).set("second", 0)
                .format("yyyy-MM-DD HH:mm:ss")
    }
}

export async function insertFactureApiKeyForUser(userUid, data) {
    await update(child(dbRef, `users/${userUid}/protected/efakture`), data);
    await addUserLog(userUid, LOG_ACTIONS.createApiKey, data);
}

export async function addUserLog(userUid, action, data) {
    const operator = getSessionOperator();
    return push(child(dbRef, `var/log/bss`), {
        operator: operator?.username || null,
        tenant: AUTH.currentUser.uid,
        user: userUid,
        action: action,
        time: new Date().toISOString(),
        data: JSON.stringify(data),
    });
}

export async function getAllLogs(field, value, startKey = null, pageSize = 10) {
    // return await (await get(child(dbRef, `var/log/bss`))).val();
    const bssRef = ref(RDB, "var/log/bss");
    let firebaseQuery = query(
        bssRef,
        limitToFirst(pageSize + 1)
    );
    if (field && value) {
        firebaseQuery = query(
            bssRef,
            orderByChild(field),
            startAt(value),
            endAt(value + "\uf8ff"),
            limitToFirst(pageSize + 1)
        )
    }

    if (startKey) {
        firebaseQuery = query(
            bssRef,
            orderByChild(field),
            startAt(startKey),
            limitToFirst(pageSize + 1)
        );
    }
    const snapshot = await get(firebaseQuery);
    // const snapshot = await firebaseQuery.get();
    const items = snapshot.val();

    if (!items) {
        return {data: [], lastKey: null};
    }

    const data = Object.entries(items).map(([key, value]) => ({
        id: key,
        ...value,
    }));

    const hasNextPage = data.length > pageSize;
    const paginatedData = hasNextPage ? data.slice(0, pageSize) : data;
    const lastKey = hasNextPage ? data[data.length - 1].id : null;

    return {data: paginatedData, lastKey, hasNextPage};
}
