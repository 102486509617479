import {createAsyncThunk, createSlice, current} from "@reduxjs/toolkit";
import {advancedSearchApi, extendLicense, findClient, updateBusinessApi} from "../../api";
import {ref, uploadBytes} from "firebase/storage";
import {dbRef, storageRef} from "../../auth/FirebaseContext";
import {child, update} from "firebase/database";
import {acceptedTermsOfUse, addUserLog, isUserHaveEFacture, resetUserTermsOfUse} from "../../helper/realtimeDatabase";
import {LOG_ACTIONS} from "../../constants";

export const fetchAllAdminClients = createAsyncThunk("fetchAllAdminClients", async (data) => {
    const users = (await findClient(data)).data;
    const newData = [];
    for (const user of users) {
        const termsOfUse = await acceptedTermsOfUse(user.uid);
        const haveFactures = await isUserHaveEFacture(user.uid);
        newData.push({
            ...user,
            termsOfUse,
            haveFactures: !haveFactures
        })
    }
    return newData;
});

export const fetchAllDebtors = createAsyncThunk("fetchAllDebtors", async (type) => {
    return (await findClient({
        debtors: type
    })).data;
});

export const extendClientLicense = createAsyncThunk("extendClientLicense", async (data) => {
    return await extendLicense(data);
});

export const updateBusinessData = createAsyncThunk("updateBusinessData", async (data) => {
    return await updateBusinessApi(data);
});

// direktno nad fb
export const updateOrAddBusinessData = createAsyncThunk("updateOrAddBusinessData", async (data) => {
    const {pib, ...otherData} = data;
    await update(child(dbRef, `/public/businesses/${pib}`), otherData);
})


export const updateUserImage = createAsyncThunk("updateUserImage", async (data) => {
    const {image, userUid} = data;
    let imageRef = ref(ref(storageRef, "public/userphotos"), `${userUid}.png`);
    return await uploadBytes(imageRef, image);
});

export const fetchAdvancedSearchUsers = createAsyncThunk("fetchAdvancedSearchUsers", async (data) => {
    return (await advancedSearchApi(data)).data;
});

export const resetTermsOfUse = createAsyncThunk(
    "resetTermsOfUse",
    async (data) => {
        await addUserLog(data.uid, LOG_ACTIONS.resetTermsOfUse, data);
        await resetUserTermsOfUse(data.uid, data.index);
        const termsOfUse = await acceptedTermsOfUse(data.uid);
        return {
            uid: data.uid,
            termsOfUse,
        }
    })

const initialState = {
    allUsers: [],
    advancedSearchUsers: [],
    allDebtors: [],
    loading: false,
    loadingAdvancedSearch: false,
    updating: false
};

export const slice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        resetFoundedResult: state => {
            state.allUsers = []
        },
        setUserHaveEFacture: (state, {payload}) => {
            const {userUid, haveFactures} = payload;
            const arr = [...current(state.allUsers)];
            const index = arr.findIndex(user => user.uid === userUid);
            if (index !== -1) {
                arr[index].haveFactures = haveFactures;
                state.allUsers = arr;
            }
        }
    },
    extraReducers: {
        //fetchAdvancedSearchUsers
        [fetchAdvancedSearchUsers.pending]: (state) => {
            state.loadingAdvancedSearch = true;
        },
        [fetchAdvancedSearchUsers.fulfilled]: (state, {payload}) => {
            state.advancedSearchUsers = payload.users
            state.loadingAdvancedSearch = false;
        },
        [fetchAdvancedSearchUsers.rejected]: (state) => {
            state.loadingAdvancedSearch = false;
        },
        //fetchAllAdminClients
        [fetchAllAdminClients.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllAdminClients.fulfilled]: (state, {payload}) => {
            state.allUsers = payload.map(item => ({
                ...item,
                contractID: item.userFinance?.contractID,
                balance: item.userFinance?.balance,
                lastSignInTime: item.metadata.lastSignInTime,
                creationTime: item.metadata.creationTime,
            }));
            state.loading = false;
        },
        [fetchAllAdminClients.rejected]: (state) => {
            state.loading = false;
        },
        //fetchAllDebtors
        [fetchAllDebtors.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllDebtors.fulfilled]: (state, {payload}) => {
            state.allDebtors = payload;
            state.loading = false;
        },
        [fetchAllDebtors.rejected]: (state) => {
            state.loading = false;
        },
        //extendClientLicense
        [extendClientLicense.pending]: (state) => {
            state.updating = true;
        },
        [extendClientLicense.fulfilled]: (state) => {
            state.updating = false;
        },
        [extendClientLicense.rejected]: (state) => {
            state.updating = false;
        },
        //updateBusinessData
        [updateBusinessData.pending]: (state) => {
            state.updating = true;
        },
        [updateBusinessData.fulfilled]: (state) => {
            state.updating = false;
        },
        [updateBusinessData.rejected]: (state) => {
            state.updating = false;
        },
        //updateUserImage
        [updateUserImage.pending]: (state) => {
            state.updating = true;
        },
        [updateUserImage.fulfilled]: (state) => {
            state.updating = false;
        },
        [updateUserImage.rejected]: (state) => {
            state.updating = false;
        },
        //resetTermsOfUse
        [resetTermsOfUse.pending]: (state) => {
            state.updating = true;
        },
        [resetTermsOfUse.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allUsers)];
            const index = arr.findIndex(user => user.uid === payload.uid);
            if (index !== -1) {
                arr[index] = {
                    ...arr[index],
                    termsOfUse: payload.termsOfUse
                };
                state.allUsers = arr;
            }
            state.updating = false;
        },
        [resetTermsOfUse.rejected]: (state) => {
            state.updating = false;
        }
    }
});
export const {resetFoundedResult, setUserHaveEFacture} = slice.actions
export default slice.reducer;


