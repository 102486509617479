import {Navigate, useRoutes} from "react-router-dom";
import GuestGuard from "../guards/GuestGuard";
import DashboardLayout from "../layouts/dashboard";
import {PATH_AFTER_LOGIN} from "../config";
import {
    AdvancedSearchPage,
    AllUsersPage,
    ChatPage,
    ClientPreviewPage,
    ClientsPage,
    DebtorsPage,
    EditBssOperatorPage,
    EditLicensePage,
    EditLocationPage,
    EditOperatorPage,
    HelpPage,
    HomePage,
    Login,
    LogsPage,
    NewBssOperatorPage,
    NewEfactureClientPage,
    NewEsirClientPage,
    NewLicensePage,
    NewLocationPage,
    NewOperatorPage,
    OperatorLogin,
    OperatorsPage,
    PaymentsPage,
    SettingsPage,
    SpecificationPage,
    SpecificationPreviewPage,
    TenantsPage,
    TenantsReportPage,
    ToolsPage,
    UserOrdersPage
} from "./elements";
import AuthGuard from "../auth/AuthGuard";
import {PATH_AUTH, PATH_DASHBOARD} from "./path";
import RoleBasedGuard from "../auth/RoleBasedGuard";
import {BSS_ROLES} from "../constants";
import ShopPage from "../pages/Shop";
import ProductDetailsPage from "../pages/Shop/ProductDetails";
import ProductManagementPage from "../pages/admin/ProductManagment";
import ProductCreatePage from "../pages/admin/ProductManagment/AddEdit/ProductCreatePage";
import ProductEditPage from "../pages/admin/ProductManagment/AddEdit/ProductEditPage";
import CheckoutPage from "../pages/Shop/Checkout";
import OrdersPage from "../pages/admin/Orders";

export default function Router() {
    return useRoutes([
        {
            path: "auth",
            children: [
                {
                    path: PATH_AUTH.login,
                    element: (
                        <GuestGuard>
                            <Login/>
                        </GuestGuard>
                    )
                },
                {
                    path: PATH_AUTH.operatorLogin,
                    element: (
                        <GuestGuard>
                            <OperatorLogin/>
                        </GuestGuard>
                    )
                }
            ]
        },
        {
            path: "",
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
                {path: PATH_DASHBOARD.general.app, element: <HomePage/>},
                {path: PATH_DASHBOARD.clients.root, element: <ClientsPage/>},
                {path: PATH_DASHBOARD.clients.newEsir, element: <NewEsirClientPage/>},
                {path: PATH_DASHBOARD.clients.newEfacture, element: <NewEfactureClientPage/>},
                {path: PATH_DASHBOARD.clients.preview, element: <ClientPreviewPage/>},
                {path: PATH_DASHBOARD.clients.newOperator, element: <NewOperatorPage/>},
                {path: PATH_DASHBOARD.clients.editOperator, element: <EditOperatorPage/>},
                {path: PATH_DASHBOARD.clients.newLocation, element: <NewLocationPage/>},
                {path: PATH_DASHBOARD.clients.editLocation, element: <EditLocationPage/>},
                {path: PATH_DASHBOARD.clients.editLicense, element: <EditLicensePage/>},
                {path: PATH_DASHBOARD.clients.newLicense, element: <NewLicensePage/>},
                {path: PATH_DASHBOARD.help.root, element: <HelpPage/>},
                {path: PATH_DASHBOARD.settings.root, element: <SettingsPage/>},
                {path: PATH_DASHBOARD.userOrders.root, element: <UserOrdersPage/>},
                {path: PATH_DASHBOARD.specification.root, element: <SpecificationPage/>},
                {path: PATH_DASHBOARD.specification.preview, element: <SpecificationPreviewPage/>},
                {
                    path: PATH_DASHBOARD.admin.orders, element:
                        <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                            <OrdersPage/>
                        </RoleBasedGuard>
                },
                {
                    path: PATH_DASHBOARD.admin.tools, element:
                        <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                            <ToolsPage/>
                        </RoleBasedGuard>
                },
                {
                    path: PATH_DASHBOARD.admin.payments, element:
                        <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                            <PaymentsPage/>
                        </RoleBasedGuard>
                },
                {
                    path: PATH_DASHBOARD.admin.logs, element:
                        <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                            <LogsPage/>
                        </RoleBasedGuard>
                },
                {
                    path: PATH_DASHBOARD.shop.root, children: [
                        {element: <ShopPage/>, index: true},
                        {path: ":name", element: <ProductDetailsPage/>},
                        {path: PATH_DASHBOARD.shop.checkout, element: <CheckoutPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.admin.allUsers.root, children: [
                        {
                            path: PATH_DASHBOARD.admin.allUsers.standard,
                            element: <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                                <AllUsersPage/>
                            </RoleBasedGuard>
                        },
                        {
                            path: PATH_DASHBOARD.admin.allUsers.advanced,
                            element: <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                                <AdvancedSearchPage/>
                            </RoleBasedGuard>
                        }
                    ]
                },
                {
                    path: PATH_DASHBOARD.admin.operators.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.admin.operators.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.admin.operators.list, element: <OperatorsPage/>},
                        {path: PATH_DASHBOARD.admin.operators.new, element: <NewBssOperatorPage/>},
                        {path: PATH_DASHBOARD.admin.operators.edit, element: <EditBssOperatorPage/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.admin.debtors.root, children: [
                        {element: <Navigate to={PATH_DASHBOARD.admin.debtors.active} replace/>, index: true},
                        {
                            path: PATH_DASHBOARD.admin.debtors.all, element: <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                                <DebtorsPage/>
                            </RoleBasedGuard>
                        },
                        {
                            path: PATH_DASHBOARD.admin.debtors.active,
                            element: <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                                <DebtorsPage/>
                            </RoleBasedGuard>
                        },
                        {
                            path: PATH_DASHBOARD.admin.debtors.inactive,
                            element: <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                                <DebtorsPage/>
                            </RoleBasedGuard>
                        }
                    ]
                },
                {
                    path: PATH_DASHBOARD.chat.root, children: [
                        {element: <ChatPage/>, index: true},
                        {path: PATH_DASHBOARD.chat.new, element: <ChatPage/>},
                        {path: ":roomId", element: <ChatPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.admin.tenants.root, children: [
                        {element: <TenantsPage/>, index: true},
                        {path: PATH_DASHBOARD.admin.tenants.new, element: <TenantsPage/>},
                        {path: PATH_DASHBOARD.admin.tenants.preview, element: <TenantsPage/>},
                        {path: PATH_DASHBOARD.admin.tenants.report, element: <TenantsReportPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.admin.products.root, children: [
                        {
                            element:
                                <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                                    <ProductManagementPage/>
                                </RoleBasedGuard>
                            , index: true
                        },
                        {
                            path: PATH_DASHBOARD.admin.products.new, element:
                                <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                                    <ProductCreatePage/>
                                </RoleBasedGuard>
                        },
                        {
                            path: PATH_DASHBOARD.admin.products.edit, element:
                                <RoleBasedGuard roles={[BSS_ROLES.admin]}>
                                    <ProductEditPage/>
                                </RoleBasedGuard>
                        }
                    ]
                }
            ]
        },
        {path: "*", element: <Navigate to={PATH_DASHBOARD.root} replace/>},
    ]);
}


